<template>
	<skeleton>
		<router-view></router-view>
	</skeleton>
</template>

<script>
import Skeleton from './components/Skeleton';
export default {
	components: {
		Skeleton
	},
	mounted: function () {
		let element = document.body;
		if (this.$root.isInsideIframe) {
			element = document.getElementsByTagName('iframe')[0].contentDocument
				.body;
		}
		element.style.backgroundImage = `url("${require('@/assets/images/menu.png')}")`;
	}
};
</script>
